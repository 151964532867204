<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <ns-company :value="model.companyId" :error="error.companyId" disabled />
        <n-field-selection :value="model.groupId" field-name="groupId" selection-name="company.group" label="group" :error="error.groupId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.operatorId" field-name="operatorId" label="operator" :error="error.operatorId" disabled />
        <n-field-text :value="model.sessionId" field-name="sessionId" label="sessionId" :error="error.sessionId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.clientAddress" field-name="clientAddress" label="clientIp" :error="error.clientAddress" disabled />
        <n-field-enum :value="model.clientType" field-name="clientType" enum-name="ClientType" label="clientType" :error="error.clientType" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-datetime :value="model.loginTime" field-name="loginTime" label="loginTime" :error="error.loginTime" disabled />
        <n-field-datetime :value="model.logoutTime" field-name="logoutTime" label="logoutTime" :error="error.logoutTime" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.accountId" field-name="accountId" label="accountId" :error="error.accountId" disabled />
        <n-field-enum :value="model.mobileType" field-name="mobileType" enum-name="MobileType" label="mobileType" :error="error.mobileType" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.serverHost" field-name="serverHost" label="serverHost" :error="error.serverHost" disabled />
        <n-field-text :value="model.serverPath" field-name="serverPath" label="serverPath" :error="error.serverPath" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.clientAgent" field-name="clientAgent" label="clientAgent" :error="error.clientAgent" disabled />
        <n-field-text :value="model.clientSystem" field-name="clientSystem" label="clientSystem" :error="error.clientSystem" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" disabled />
        <n-field-text :value="model.clientVersion" field-name="clientVersion" label="clientVersion" :error="error.clientVersion" disabled />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalCustomerAccess',
  components: { NModal },
});
</script>
