<template>
  <!-- prettier-ignore -->
  <div>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <ns-group v-model="condition.groupId" :company-id="condition.companyId" all-option />
      <n-field-id v-model="condition.customerId" label="customerId" />
      <n-field-id v-model="condition.accountId" label="accountId" />
      <n-field-text v-model="condition.sessionId" label="sessionId" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <customer-access-table :records="records" is-active></customer-access-table>
    </n-table>
  </div>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import CustomerAccessTable from './CustomerCustomerAccessTable';
import { fetch } from '@/api/customer/customer-customer-access';

export default CustomerView.extend({
  name: 'CustomerCustomerAccessActive',
  components: { CustomerAccessTable },
  mixins: [PaginateMixin],
  props: {
    condition: Object,
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(data) {
      this.records = data.records;
      this.$emit('records', this.records);
    },
  },
});
</script>
