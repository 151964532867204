<template>
  <!-- prettier-ignore -->
  <table class="table table-bordered table-hover table-striped text-center n-table">
    <thead>
    <tr>
      <th class="col-weight-12">{{ $t('label.company') }}</th>
      <th class="col-weight-12">{{ $t('label.customerId') }}</th>
      <th class="col-weight-12">{{ $t('label.accountId') }}</th>
      <th class="col-weight-12">{{ $t('label.app') }}</th>
      <th class="col-weight-17">{{ $t('label.ip') }}</th>
      <th class="col-weight-12">{{ $t('label.type') }}</th>
      <th class="n-table-timestamp">{{ $t('label.loginTime') }}</th>
      <th class="n-table-timestamp">{{ $t('label.logoutTime') }}</th>
      <th class="action-detail">{{ $t('label.action') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="row in records" :key="row.id">
      <td v-selection:[`company.company`]="row.companyId"></td>
      <td class="n-ellipsis">
        <n-modal-link v-if="isActive" name="customer.profile" :value="row.customerId" />
        <span v-else>{{ row.customerId }}</span>
      </td>
      <td class="n-ellipsis">
        <n-modal-link v-if="isActive" name="customer.account" :value="row.accountId" />
        <span v-else>{{ row.accountId }}</span>
      </td>
      <td>{{ `${row.appName}:${row.appId}` }}</td>
      <td class="n-ellipsis" :title="row.clientAddress">{{ row.clientAddress }}</td>
      <td v-enums:ClientType="row.clientType"></td>
      <td>{{ row.loginTime | datetime }}</td>
      <td>{{ row.logoutTime | datetime }}</td>
      <td class="n-button-box">
        <nb-custom type="info" text="detail" @on-click="openModal(row)" />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ModalCustomerAccess from './ModalCustomerAccess';
import { createModal } from '@/helpers/modal';

export default {
  name: 'CustomerAccessTable',
  props: {
    isActive: Boolean,
    records: Array,
  },
  methods: {
    openModal(model = {}) {
      createModal(ModalCustomerAccess, { model, on: this });
    },
  },
};
</script>
