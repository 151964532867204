import { $fetch } from '@/services/axios';

export const CUSTOMER_ACCESS_ACTIVE_CSV_URL = `customer/accesses/csv`;
export const CUSTOMER_ACCESS_HISTORY_CSV_URL = `customer/accesses/history/csv`;

export function fetch(params) {
  return $fetch(`customer/accesses`, params);
}

export function history(params) {
  return $fetch(`customer/accesses/history`, params);
}
